import React, { Component } from 'react'
import styled from "styled-components";

export default class Films extends Component {

    componentDidMount() {
        document.title = 'Films | Timothy Zhou';
    }

    render() {
        return (
            <div>

            </div>
        )
    }

}